<template>
  <div class="w">
    <div class="top">
      <div class="banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="`swiper-slide${index + 1}`" v-for="(item, index) in bannerPicture" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
        <div class="swiper-container2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :class="`swiper-slide${index + 1}`" v-for="(item, index) in bannerPicture" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="address">
        <div class="title-box">
          <div class="left">
            <div class="big-title">园区地址</div>
            <div class="text">
              <div class="icon">
                <img src="@/assets/images/operate-image/page27.png" alt="">
              </div>
              <span>{{ form.parkAddress }}</span>
            </div>
          </div>
          <div class="right">
            <div class="big-title">联系我们</div>
            <div class="text">{{ form.contact || '0755-82263776'}}</div>
          </div>
        </div>
        <div class="img-box">
          <img src="@/assets/images/operate-image/page26.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick,
  reactive, toRefs, watchEffect,
} from 'vue';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      swiper: null,
      swiper2: null,
      bannerPicture: [],
    });
    const initSwiper = () => {
      data.swiper = new Swiper('.swiper-container', {
        grabCursor: true,
        loop: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false,
        },
      });
    };
    const initSwiper2 = () => {
      data.swiper2 = new Swiper('.swiper-container2', {
        grabCursor: true,
        loop: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false,
        },
      });
    };
    watchEffect(() => {
      if (props.form.bannerPicture) {
        data.bannerPicture = props.form.bannerPicture.split(',');
        nextTick(() => {
          initSwiper();
          initSwiper2();
        });
      }
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.top {
  height: 23.958vw;
  display: flex;
  overflow: hidden;
  .banner {
    width: 40.625vw;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-container {
      width: 100%;
      height: 100%;
      position: relative;
        .swiper-slide {
        background-color: #fff;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        color: #fff;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .swiper-container2 {
      display: none;
    }

  }
  .address {
    flex: 1;
    padding: 1.458vw 1.563vw;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .title-box {
      height: 100%;
      display: flex;
      margin-bottom: 1.458vw;
      .left {
        width: 19.792vw;
      }
      .right {
        flex: 1;
      }
      .big-title {
        height: 2.5vw;
        font-size: 1.25vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 1.458vw;
      }
      .text {
        height: 1.302vw;
        font-size: 0.938vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #064A6C;
        line-height: 1.094vw;
        display: flex;
        align-items: center;
        overflow: hidden;
        .icon {
          width: 0.885vw;
          height: 1.25vw;
          margin-right: 0.417vw;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    .img-box {
      flex: 1;
      width: 100%;
      background-color: red;
    }
  }
}
@media screen and (max-width: 768px) {
  .top {
    height: auto;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    .banner {
      width: 100vw;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .swiper-container2 {
        width: 100%;
        height: 50vw;
        position: relative;
        display: block;
          .swiper-slide {
          background-color: #fff;
          transform-style: preserve-3d;
          transition: transform 0.6s;
          color: #fff;
          text-align: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .swiper-container {
        display: none;
      }
    }
    .address {
      flex: 1;
      padding: 1.458vw 1.563vw;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 1.333vw;
      .title-box {
        height: 100%;
        display: flex;
        margin-bottom: 1.458vw;
        flex-direction: column;
        .left {
          width: 100%;
          flex: 1;
        }
        .right {
          flex: 1;
        }
        .big-title {
          height: auto;
          font-size: 2.4vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 4.8vw;
        }
        .text {
          height: auto;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #064A6C;
          line-height: 4.267vw;
          display: flex;
          align-items: center;
          .icon {
            width: 2vw;
            height: 2.5vw;
            margin-right: 1.417vw;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }
      .img-box {
        display: none;
        flex: 1;
        width: 100%;
        background-color: red;
      }
    }
  }
}
</style>
