<template>
  <div class="w overview">
    <div class="img-box">
      <img src="@/assets/images/operate-image/page21.png" alt="">
    </div>
    <div class="bor"></div>
    <div class="text">
      {{ form.parkSituation }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  .img-box {
    width: 10.26vw;
    height: 2.396vw;
    margin-bottom: 0.677vw;
    margin-top: 3.281vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bor {
    height: 1px;
    width: 100%;
    background-color: #D9D9D9;
    margin-bottom: 2.24vw;
  }
  .text {
    font-size: 0.938vw;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 1.875vw;
    text-indent: 1.875vw;
  }
}
@media screen and (max-width: 768px) {
  .overview {
    .img-box {
      width: 35vw;
      height: auto;
      margin-bottom: 0.677vw;
      margin-top: 3.281vw;
      margin-left: 4vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bor {
      height: 1px;
      width: 100%;
      background-color: #D9D9D9;
      margin-bottom: 2.24vw;
    }
    .text {
      font-size: 1.867vw;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 4.267vw;
      text-indent: 3.733vw;
      padding: 0 2vw;
    }
  }
}
</style>
