<template>
  <div class="w content">
    <div class="img-box">
      <img src="@/assets/images/operate-image/page20.png" alt="">
    </div>
    <div class="bor"></div>
    <div class="item-box">
      <div class="left">
        <div class="row" v-for="(item, index) in leftList" :key="index">
          <div class="name">{{ item.name }}</div>
          <div class="text">{{ form[item.key] || '--' }}</div>
        </div>
      </div>
      <div class="right">
        <div class="row" v-for="(item, index) in rightList" :key="index">
          <div class="name">{{ item.name }}</div>
          <div class="text">{{ form[item.key] || '--' }}</div>
        </div>
      </div>
    </div>
    <div class="back curr" @click="back">
      <img src="@/assets/images/news-image/back.png" alt="">
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const router = useRouter();
    const leftList = [
      {
        key: 'floorArea',
        name: '占地面积：',
      },
      {
        key: 'buildingArea',
        name: '建筑面积：',
      },
      {
        key: 'rentalArea',
        name: '可租赁面积：',
      },
      {
        key: 'buildingStructure',
        name: '建筑结构：',
      },
      {
        key: 'insideHeight',
        name: '库内净高：',
      },
      {
        key: 'insideGround',
        name: '库内地面：',
      },
      {
        key: 'insideTemperature',
        name: '库温：',
      },
      {
        key: 'parkingSpace',
        name: '停车位：',
      },
      {
        key: 'platformFormat',
        name: '月台形式：',
      },
      {
        key: 'groundLoad',
        name: '地面荷载：',
      },
      {
        key: 'parkFacility',
        name: '园区配套：',
      },
      {
        key: 'liftPlatform',
        name: '升降平台：',
      },
    ];
    const rightList = [
      {
        key: 'fireRating',
        name: '耐火等级：',
      },
      {
        key: 'storageCategory',
        name: '消防等级：',
      },
      {
        key: 'fireProtectionSystem',
        name: '消防系统：',
      },
      {
        key: 'lightingSystem',
        name: '照明系统：',
      },
      {
        key: 'havingCanopy',
        name: '是否有雨棚：',
      },
      {
        key: 'propertyServices',
        name: '物业服务：',
      },
      {
        key: 'slidingAndLifting',
        name: '滑升门&升降台：',
      },
      {
        key: 'generatorAndVentilation',
        name: '备用发动机&通风系统：',
      },
      {
        key: 'outdoorWidth',
        name: '室外周转场地宽：',
      },
      {
        key: 'powerEquipment',
        name: '电力设备：',
      },
      {
        key: 'compressorUnit',
        name: '压缩机组：',
      },
    ];

    const back = () => {
      router.back();
    };
    return {
      leftList,
      rightList,
      back,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  overflow: hidden;
  .img-box {
    width: 22.813vw;
    height: 2.396vw;
    margin-bottom: 0.677vw;
    margin-top: 3.125vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bor {
    height: 1px;
    width: 100%;
    background-color: #D9D9D9;
    margin-bottom: 1.146vw;
  }
  .item-box {
    display: flex;
    height: 34.896vw;
    background-color: #fff;
    .left {
      flex: 1;
      border-right: 1px solid #E6E6E6;
      padding: 1.25vw 2.188vw;
    }
    .right {
      flex: 1;
      padding: 1.25vw 2.188vw;
    }
    .row {
      width: 100%;
      display: flex;
      height: 2.396vw;
      font-size: 0.833vw;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      line-height: 2.396vw;
      justify-content: space-between;
      .name {
        color: #666666;
      }
      .text {
        font-weight: bold;
        color: #000;
      }
    }
  }
  .back {
    float: right;
    width: 5.625vw;
    height: 2.5vw;
    margin-top: 2.188vw;
    margin-bottom: 3.438vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .content {
    overflow: hidden;
    .img-box {
      width: 70vw;
      height: auto;
      margin-bottom: 0.677vw;
      margin-top: 3.125vw;
      margin-left: 4vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bor {
      height: 1px;
      width: 100%;
      background-color: #D9D9D9;
      margin-bottom: 1.146vw;
    }
    .item-box {
      display: flex;
      height: auto;
      background-color: #fff;
      flex-direction: column;
      padding: 2vw;
      .left {
        flex: 1;
        border-right: none;
        padding: 0;
      }
      .right {
        flex: 1;
        padding: 0;
      }
      .row {
        width: 100%;
        display: flex;
        height: auto;
        font-size: 1.867vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        line-height: 4.267vw;
        justify-content: space-between;
        .name {
          color: #666666;
        }
        .text {
          font-weight: bold;
          color: #000;
        }
      }
    }
    .back {
      float: right;
      width: 14.4vw;
      height: 6.4vw;
      margin-right: 4vw;
      margin-top: 5.6vw;
      margin-bottom: 8.8vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
