<template>
  <div class="w content">
    <div class="img-box">
      <img src="@/assets/images/operate-image/page19.png" alt="">
    </div>
    <div class="bor"></div>
    <div class="item-box">
      <template v-for="(item, index) in list" :key="index">
        <div class="item" v-if="arr.includes(item.title)">
          <div class="img-box2">
            <img :src="item.img" alt="">
          </div>
          <div class="title">{{ item.title }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/operate-image/page22.png';
import img2 from '@/assets/images/operate-image/page23.png';
import img3 from '@/assets/images/operate-image/page24.png';
import img4 from '@/assets/images/operate-image/page25.png';
import { reactive, toRefs, watchEffect } from 'vue';

export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = reactive({
      arr: [],
    });
    const list = [
      {
        title: '库内服务',
        img: img4,
      },
      {
        title: '增值服务',
        img: img3,
      },
      {
        title: '运输服务',
        img: img2,
      },
      {
        title: '一件代发',
        img: img1,
      },
    ];
    watchEffect(() => {
      if (props.form.serviceContent) {
        data.arr = props.form.serviceContent.split(',');
      }
    });
    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .img-box {
    width: 25.156vw;
    height: 2.448vw;
    margin-bottom: 0.677vw;
    margin-top: 3.281vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .bor {
    height: 1px;
    width: 100%;
    background-color: #D9D9D9;
    margin-bottom: 5.729vw;
  }
  .item-box {
    display: flex;
    justify-content: space-around;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img-box2 {
        width: 7.24vw;
        height: 7.24vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title {
        height: 2.396vw;
        font-size: 1.25vw;
        font-family: Microsoft PhagsPa-Bold, Microsoft PhagsPa;
        font-weight: bold;
        color: #000000;
        line-height: 2.396vw;
        margin-top: 1.458vw;
      }
    }

  }
}
@media screen and (max-width: 768px) {
  .content {
    .img-box {
      width: 70vw;
      height: auto;
      margin-bottom: 0.677vw;
      margin-top: 3.281vw;
      margin-left: 4vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .bor {
      height: 1px;
      width: 100%;
      background-color: #D9D9D9;
      margin-bottom: 5.729vw;
    }
    .item-box {
      display: flex;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img-box2 {
          width: 7.24vw;
          height: 7.24vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          height: 2.396vw;
          font-size: 1.25vw;
          font-family: Microsoft PhagsPa-Bold, Microsoft PhagsPa;
          font-weight: bold;
          color: #000000;
          line-height: 2.396vw;
          margin-top: 1.458vw;
        }
      }

    }
  }
}
</style>
